import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Text from '@/components/Text'

import * as Routs from '@/routs.js'
// import useWindowWidth from '@/_helpers/Resize/useWindowWidth
import PageLayout from '@/Layouts/PageLayout'
import useClass from '@/_helpers/Hooks/useClass'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import { CreateReportReq, GetTagsReq } from '@/pages/Lists/model/service'
import Icons from '@/components/Icons'
import { useQuery } from '@apollo/client'

import { ITag } from './model/types'
import { PageHeader } from './CatalogOfLists/components/PageHeader'
import CatalogOfLists from './CatalogOfLists'
import { getFavoritesListReq } from './model/service'
import { GET_RECENT_SEARCHES_COUNT } from './model/graphQL'

import cn from './Lists.module.scss'
import DownloadModal from '@/pages/Lists/components/DownloadModal'
import ModalDownloadInfo from '@/pages/Lists/components/ModalDownloadInfo'
import { ReactComponent as IconClock } from '@/pages/Lists/icons/clock.svg'
import { ReactComponent as IconHeart } from '@/pages/Lists/icons/heart.svg'

const Lists = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const token = useToken()
    const [tags, setTags] = useState<ITag[]>([])

    const [activeTag, setActiveTag] = useState(-1)
    const [show, setShow] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        if (!token) return
        GetTagsReq(token).then((res) => {
            setTags(res)
        })
    }, [token])

    const root = useClass(cn.root)

    const [likesCount, setLikesCount] = useState<number>(0)
    const getFavoritesID = useCallback(() => {
        if (!token) {
            return
        }
        getFavoritesListReq(token)
            .then((res) => {
                if (res && res.results && res.results.length > 0) setLikesCount(res.results[0].qty)
            })
            .catch((er) => {
                console.log(er)
            })
    }, [token])
    useEffect(() => {
        getFavoritesID()
    }, [getFavoritesID])

    const changeModalHandler = () => {
        setShow(true)
    }

    const { data: recentSearchesCount } =
        useQuery<{ getRecentSearchesV2: { count: number } }>(GET_RECENT_SEARCHES_COUNT)

    const callback = useCallback(
        async (id: number) => {
            await CreateReportReq(token as string, id, true)
            setShow(false)
            setShowInfo(true)
        },
        [token, setShow]
    )

    const goToDownload = () => {
        history.push('/audience/downloads')
        setShowInfo(false)
    }

    return (
        <>
            <PageLayout color={'common-background'} className={root} title={t('titles.t2')}>
                <div className={cn.layout} id={'listLayoutID'}>
                    <PageHeader />
                    <Flex className={cn['navigate-wrapper']}>
                        <Link to={`${Routs.LISTS + Routs.RECENT_SEARCHES}`}>
                            <Flex className={cn['navigate']}>
                                <Flex className={cn['navigate-item']}>
                                    <IconClock />
                                    <Text color="gray-1" fSize={'14px'} semibold>
                                        {t('aud_insides.lists.RECENT')}
                                    </Text>
                                    <span>{recentSearchesCount?.getRecentSearchesV2.count || 0}</span>
                                </Flex>
                                <Icons name="arrow-right" />
                            </Flex>
                        </Link>
                        <Link to={`${Routs.LISTS + Routs.FAVORITES}`}>
                            <Flex className={cn['navigate']}>
                                <Flex className={cn['navigate-item']}>
                                    <IconHeart />
                                    <Text color="gray-1" fSize={'14px'} semibold>
                                        {t('aud_insides.lists.favorites')}
                                    </Text>
                                    <span>{likesCount}</span>
                                </Flex>
                                <Icons name="arrow-right" />
                            </Flex>
                        </Link>
                    </Flex>
                    <Flex margin={'0 0 6px 0'} className={cn.tags}>
                        <Flex wrap className={cn['tags--all']}>
                            <Flex
                                onClick={() => setActiveTag(-1)}
                                className={cn[`tags--container${activeTag === -1 ? '-active' : ''}`]}
                                align="center"
                                content="center"
                                margin={'0 10px 10px 0'}
                            >
                                <Icons
                                    name={'lists'}
                                    className={cn[`tags--icon${activeTag === -1 ? '-active' : ''}`]}
                                />
                                <p className={cn[`tags--text${activeTag === -1 ? '-active' : ''}`]}>
                                    {t('aud_insides.tags.all_lists')}
                                </p>
                            </Flex>
                            {tags.map((tag, i) => (
                                <>
                                    <Flex
                                        key={i}
                                        onClick={() => setActiveTag(i)}
                                        className={cn[`tags--container${activeTag === i ? '-active' : ''}`]}
                                        align="center"
                                        content="center"
                                        margin={'0 10px 10px 0'}
                                    >
                                        <p className={cn[`tags--text${activeTag === i ? '-active' : ''}`]}>
                                            #{tag.name}
                                        </p>
                                    </Flex>
                                </>
                            ))}
                        </Flex>
                    </Flex>
                    <DownloadModal onClose={() => setShowInfo(false)} isOpen={showInfo} callback={goToDownload} />
                    <ModalDownloadInfo isOpen={show} closeModal={() => setShow(false)} callback={callback} />
                    <CatalogOfLists
                        openModal={changeModalHandler}
                        activeTag={activeTag !== -1 ? tags[activeTag].name : undefined}
                    />
                </div>
            </PageLayout>
        </>
    )
}
export default Lists
