import { Platform } from '@/pages/Reports/types'
import { IGetRecentSearchesData, INormalListData, ICustomListReq, FavoriteBloggers } from '../model/types'
// import { shortNum } from '@/_helpers/_graphs/_tech'
import { VkUnlocked, VkAccountType } from '@/pages/SearchPage/SearchAdapter/bloggers/vk/utils/types'

const YUOTUBE_PERCENTS = {
    COLLECTION_INFO: 0.2,
    COLLECTION_VIDEO: 0.3,
    COLLECTION_COMMENT: 0.5,
    COLLECTION_AUDIENCE: 0.7,
    CALCULATE_AUDIENCE: 0.9,
    ON_PARSING: 0.1,
} as { [key: string]: number }

const VK_PERCENTS = {
    NEW: 0.1,
    PARSING_INFO: 0.3,
    PARSING_POSTS: 0.5,
    PARSING_FOLLOWERS: 0.7,
    CALCULATE_AUDIENCE: 0.8,
    CALCULATE_METRICS: 0.9,
} as { [key: string]: number }

const TG_PERCENTS = {
    NEW: 0.1,
    PARSING_INFO: 0.3,
    PARSING_POSTS: 0.5,
    PARSING_FOLLOWERS: 0.7,
    CALCULATE_AUDIENCE: 0.8,
    CALCULATE_METRICS: 0.9,
} as { [key: string]: number }

const getPercent = (status: string) => {
    if (!YUOTUBE_PERCENTS[status]) {
        return 0.01
    }
    return YUOTUBE_PERCENTS[status]
}
const getVkPercent = (status: string) => {
    if (!VK_PERCENTS[status]) {
        return 0.01
    }

    return VK_PERCENTS[status]
}
const getTgPercent = (status: string) => {
    if (!TG_PERCENTS[status]) {
        return 0.01
    }

    return TG_PERCENTS[status]
}

export const RecentSearchToNormal = (data: IGetRecentSearchesData, likeBloggers: FavoriteBloggers[]) => {
    let normalList: INormalListData[] = []

    data.data
        .filter(
            (item) =>
                item.data.status !== 'NOT_ACTIVE' &&
                item.data.status !== 'DELETED' &&
                item.data.status !== 'ANY_ERROR' &&
                item.data.status !== 'PRIVATE' &&
                item.data.status !== 'TOO_SMALL' &&
                item.data.status !== 'NEW'
        )
        .filter((item) => {
            return Object.keys(item.data).length !== 0 && item.data?.id !== undefined
        })
        .forEach((item) => {
            if (Object.keys(item.data).length === 0) return
            if (item.type === 'instagram') {
                let readyStatus = item.data.ready_status !== 1 ? item.data.ready_status : undefined
                if (readyStatus === 0) {
                    readyStatus = 0.01
                }
                normalList.push({
                    avatar: item.data.avatar,
                    fullName: item.data.full_name ? item.data.full_name : null,
                    userName: item.data.username ? item.data.username : '',
                    followers: item.data.followers ? item.data.followers : 0,
                    er: item.data.er ? (parseFloat(item.data.er) * 100).toFixed(2).toString() : '',
                    aq: item.data.aq ? (parseFloat(item.data.aq) * 100).toFixed() + '%' : '',
                    id: item.id,
                    bloggerId: item.data.id,
                    external_id: item.data.external_id ? item.data.external_id : '',
                    type: item.type,
                    unlocked: true,
                    statusPercent: readyStatus,
                    has_email: item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.external_id === item.data?.external_id).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data?.external_id)[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.external_id === item.data?.external_id).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data?.external_id)[0].id
                            : undefined,
                    yoscore: item.data.yo_score || undefined,
                    topics: item.data.topics && item.data.topics?.length > 0 ? item.data.topics[0] : undefined,
                })
            } else if (item.type === 'twitch') {
                normalList.push({
                    avatar: item.data.profile_image_url ? item.data.profile_image_url : '',
                    fullName: null,
                    userName: item.data.login ? item.data.login : '',
                    followers: item.data.subscriber_count ? item.data.subscriber_count : 0,
                    er: item.data.er_views ? `${(item.data.er_views * 100).toFixed(2)}` : '',
                    topics:
                        item.data?.channel_topics &&
                        item.data?.channel_topics[0] &&
                        'youtube_channeltopic' in item.data.channel_topics[0]
                            ? item.data.channel_topics[0]?.youtube_channeltopic?.name
                            : undefined,
                    id: item.id,
                    channelId: item.data.id ? item.data.id.toString() : '',
                    external_id: item.data.id ? item.data.id.toString() : '',
                    type: item.type,
                    unlocked: true,
                    has_email: item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.external_id === item.data.id.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id.toString())[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.external_id === item.data.id.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id.toString())[0].id
                            : undefined,
                    yoscore: item.data.yo_score || undefined,
                })
            } else if (item.type === 'vk') {
                if (item.data.full_name === null && item.data.username === null) return
                normalList.push({
                    avatar: item.data.avatar_link || '',
                    fullName: item.data.full_name ? item.data.full_name : null,
                    userName: item.data.username ? item.data.username : '',
                    followers: item.data.followers ? item.data.followers : 0,
                    er: item.data.er_views ? `${(item.data.er_views * 100).toFixed(2)}` : '',
                    id: item.id,
                    channelId: item.data.account_id ? item.data.account_id : '',
                    external_id: item.data.account_id ? item.data.account_id : '',
                    type: item.type,
                    unlocked: true,
                    statusPercent:
                        item.data.status && item.data.status !== 'READY' && item.data.status !== 'DONE'
                            ? getVkPercent(item.data.status)
                            : 1,
                    has_email: false, //item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.external_id === item.data.account_id).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.account_id)[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.external_id === item.data.account_id).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.account_id)[0].id
                            : undefined,
                    yoscore: item.data.yo_score || undefined,
                    account_type: item.data.account_type,
                    topics: item.data?.vk_accounttopic?.map((item) => item?.vk_topic.name) ?? undefined,
                })
            } else if (item.type === 'telegram') {
                normalList.push({
                    avatar: item.data.avatar || '',
                    fullName: item.data.title ? item.data.title : null,
                    userName: item.data.username ? item.data.username : '',
                    followers: item.data.followers ? item.data.followers : 0,
                    er: item.data.er_views ? `${(item.data.er_views * 100).toFixed(2)}` : '',
                    topics:
                        item.data?.channel_topics &&
                        item.data?.channel_topics[0] &&
                        'telegram_topic' in item.data.channel_topics[0]
                            ? item.data.channel_topics[0].telegram_topic.name
                            : undefined,
                    id: item.id,
                    channelId: item.data.id ? item.data.id.toString() : '',
                    external_id: item.data.id ? item.data.id.toString() : '',
                    type: Platform.Tg,
                    unlocked: true,
                    statusPercent: 1,
                    // item.data.status && item.data.status !== 'READY' && item.data.status !== 'DONE'
                    //     ? getVkPercent(item.data.status)
                    //     : 1,
                    has_email: false, //item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.channel_id === item.data.id.toString()).length > 0
                            ? likeBloggers.filter((e) => e.channel_id === item.data.id.toString())[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.channel_id === item.data.id.toString()).length > 0
                            ? likeBloggers.filter((e) => e.channel_id === item.data.id.toString())[0].id
                            : undefined,
                    yoscore: undefined,
                })
            } else if (item.type === 'tiktok') {
                normalList.push({
                    avatar: item.data.avatar,
                    fullName: item.data.full_name ? item.data.full_name : null,
                    userName: item.data.username ? item.data.username : '',
                    followers: item.data.followers ? item.data.followers : 0,
                    er: item.data.er_views ? `${(item.data.er_views * 100).toFixed(2)}` : '',
                    topics: [],
                    // item.data?.channel_topics &&
                    //     item.data?.channel_topics[0] &&
                    //     'youtube_channeltopic' in item.data.channel_topics[0]
                    //     ? item.data.channel_topics[0]?.youtube_channeltopic?.name
                    //     : undefined,
                    id: item.id,
                    channelId: item.data.id ? item.data.id.toString() : '',
                    external_id: item.data.id ? item.data.id.toString() : '',
                    type: item.type,
                    unlocked: true,
                    statusPercent: item.data.status && item.data.status !== 'READY' ? getPercent(item.data.status) : 1,
                    has_email: item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.external_id === item.data.id?.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id?.toString())[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.external_id === item.data.id?.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id?.toString())[0].id
                            : undefined,
                    yoscore: item.data.yo_score || undefined,
                })
            } else {
                normalList.push({
                    avatar: item.data.avatar,
                    fullName: item.data.full_name ? item.data.full_name : null,
                    userName: item.data.title ? item.data.title : '',
                    followers: item.data.subscriber_count ? item.data.subscriber_count : 0,
                    er: item.data.er_views ? `${(item.data.er_views * 100).toFixed(2)}` : '',
                    topics:
                        item.data?.channel_topics &&
                        item.data?.channel_topics[0] &&
                        'youtube_channeltopic' in item.data.channel_topics[0]
                            ? item.data.channel_topics[0]?.youtube_channeltopic?.name
                            : undefined,
                    id: item.id,
                    channelId: item.data.id ? item.data.id.toString() : '',
                    external_id: item.data.id ? item.data.id.toString() : '',
                    type: item.type,
                    unlocked: true,
                    statusPercent: item.data.status && item.data.status !== 'READY' ? getPercent(item.data.status) : 1,
                    has_email: item.data.has_email || false,
                    isLike:
                        likeBloggers.filter((e) => e.external_id === item.data.id?.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id?.toString())[0].id
                            : undefined,
                    likedID:
                        likeBloggers.filter((e) => e.external_id === item.data.id?.toString()).length > 0
                            ? likeBloggers.filter((e) => e.external_id === item.data.id?.toString())[0].id
                            : undefined,
                    yoscore: item.data.yo_score || undefined,
                })
            }
        })
    return normalList
}
export const CustomListsToNormal = (
    data: ICustomListReq[],
    unlockedChannels: {
        unlockedIDS: string[]
        vkUnlocked: VkUnlocked[]
    },
    likeBloggers: FavoriteBloggers[]
) => {
    const ifUnlockedChannel = (channel_id: string, vkAccountType?: VkAccountType) => {
        if (vkAccountType) {
            return (
                unlockedChannels.vkUnlocked.filter(
                    (item) => item.vk_account_id === channel_id && item.account_type === vkAccountType
                ).length > 0
            )
        }

        return unlockedChannels.unlockedIDS.includes(channel_id)
    }
    let normalList: INormalListData[] = []

    data.forEach((item) => {
        if (item.hasOwnProperty('blogger')) {
            if (item.blogger === undefined) return null
            let readyStatus = item.blogger.ready_status !== 1 ? item.blogger.ready_status : undefined
            if (readyStatus === 0) {
                readyStatus = 0.01
            }
            normalList.push({
                avatar: item.blogger.avatar,
                fullName: item.blogger.full_name,
                userName: item.blogger.username,
                followers: item.blogger.followers,
                bloggerId: item.blogger.id,
                er: (parseFloat(item.blogger.er) * 100).toFixed(2).toString(),
                aq: item.blogger.aq
                    ? item.blogger.aq.length !== 0
                        ? (parseFloat(item.blogger.aq) * 100).toFixed() + '%'
                        : ''
                    : '',
                id: item.id,
                external_id: item.blogger.external_id,
                has_email: item.blogger.has_email,
                type: Platform.Instagram,
                unlocked: item.unlocked ? item.unlocked : false,
                statusPercent: readyStatus,
                isLike:
                    likeBloggers.filter((e) => e.external_id === item.blogger?.external_id).length > 0
                        ? likeBloggers.filter((e) => e.external_id === item.blogger?.external_id)[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.external_id === item.blogger?.external_id).length > 0
                        ? likeBloggers.filter((e) => e.external_id === item.blogger?.external_id)[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
                yoscore: item.blogger.yo_score || undefined,
                topics: item.blogger.topics && item.blogger.topics?.length > 0 ? item.blogger.topics[0] : undefined,
            })
        } else if (item.resourcetype === 'SearchSetTwitchChannel') {
            if (item.channel === undefined) return null
            normalList.push({
                avatar: item.channel.avatar,
                fullName: item.channel.fullName || '',
                userName: item.channel.title,
                followers: item.channel.subscriber_count,
                er: item.channel.er_views ? `${(item.channel.er_views * 100).toFixed(2)}` : '',
                aq: '-1',
                id: item.id,
                channelId: item.twitch_channel_id ? item.twitch_channel_id : '',
                external_id: item.twitch_channel_id ? item.twitch_channel_id : '',
                type: Platform.Twitch,
                has_email: item.channel.has_email,
                unlocked: item.twitch_channel_id ? ifUnlockedChannel(item.twitch_channel_id) : false,
                isLike:
                    likeBloggers.filter((e) => e.twitch_channel_id === item.twitch_channel_id).length > 0
                        ? likeBloggers.filter((e) => e.twitch_channel_id === item.twitch_channel_id)[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.twitch_channel_id === item.twitch_channel_id).length > 0
                        ? likeBloggers.filter((e) => e.twitch_channel_id === item.twitch_channel_id)[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
            })
        } else if (item.resourcetype === 'SearchSetVkAccount') {
            if (item.channel === undefined) return null
            normalList.push({
                avatar: item.channel.avatar,
                fullName: item.channel.fullName || '',
                userName: item.channel.title,
                followers: item.channel.subscriber_count,
                er: item.channel.er_views ? `${(item.channel.er_views * 100).toFixed(2)}` : '', // ? `${(item.channel.er_views * 100).toFixed(2)}` : '',
                aq: '-1',
                id: item.id,
                channelId: item.vk_account_id ? item.vk_account_id : '',
                external_id: item.vk_account_id ? item.vk_account_id : '',
                type: Platform.Vk,
                has_email: item.channel.has_email,
                statusPercent: item.channel?.status !== 'DONE' ? getVkPercent(item.channel?.status) : 1,
                unlocked: item.vk_account_id ? ifUnlockedChannel(item.vk_account_id, 'group') : false,
                isLike:
                    likeBloggers.filter((e) => e.vk_account_id === item.vk_account_id).length > 0
                        ? likeBloggers.filter((e) => e.vk_account_id === item.vk_account_id)[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.vk_account_id === item.vk_account_id).length > 0
                        ? likeBloggers.filter((e) => e.vk_account_id === item.vk_account_id)[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
                topics:
                    item.channel.channel_topics && item.channel.channel_topics[0]
                        ? item.channel.channel_topics.map((item) => item?.vk_topic?.name || '') || []
                        : [],
                account_type: item.channel.account_type || undefined,
            })
        } else if (item.resourcetype === 'SearchSetTelegramAccount') {
            if (item.channel === undefined) return null
            normalList.push({
                avatar: item.channel.avatar,
                fullName: item.channel.title || '',
                userName: item.channel.username || '',
                followers: item.channel.subscriber_count,
                er: item.channel.er_views ? `${(item.channel.er_views * 100).toFixed(2)}` : '',
                aq: '-1',
                id: item.id,
                channelId: item.channel_id ? item.channel_id : '',
                external_id: item.channel_id ? item.channel_id : '',
                type: Platform.Tg,
                has_email: item.channel.has_email,
                statusPercent: item.channel?.status !== 'DONE' ? getTgPercent(item.channel?.status) : 1,
                unlocked: item.channel_id ? ifUnlockedChannel(item.channel_id) : false,
                isLike:
                    likeBloggers.filter((e) => e.channel_id === item.channel_id).length > 0
                        ? likeBloggers.filter((e) => e.channel_id === item.channel_id)[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.channel_id === item.channel_id).length > 0
                        ? likeBloggers.filter((e) => e.channel_id === item.channel_id)[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
                topics:
                    item.channel.channel_topics && item.channel.channel_topics[0]
                        ? item.channel.channel_topics.map((item) => item?.telegram_topic?.name || '')
                        : [],
            })
        } else if (item.resourcetype === 'SearchSetTikTokAccount') {
            if (item.channel === undefined) return null
            normalList.push({
                avatar: item.channel.avatar,
                fullName: '',
                userName: item.channel.username || '',
                followers: item.channel.subscriber_count,
                er: item.channel.er_views ? `${(item.channel.er_views * 100).toFixed(2)}` : '',
                aq: '-1',
                id: item.id,
                channelId: item.channel?.id?.toString(),
                external_id: item.channel?.id?.toString() || '',
                type: Platform.TikTok,
                has_email: false, // У TikTok, возможно, нет почтовых данных
                unlocked: ifUnlockedChannel(item.channel?.id?.toString() || ''),
                isLike:
                    likeBloggers.filter((e) => e.external_id === item?.channel?.id?.toString()).length > 0
                        ? likeBloggers.filter((e) => e.external_id === item?.channel?.id?.toString())[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.external_id === item?.channel?.id?.toString()).length > 0
                        ? likeBloggers.filter((e) => e.external_id === item?.channel?.id?.toString())[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
                topics: [], // Если в будущем добавятся темы для TikTok, их можно обработать здесь
            })
        } else {
            if (item.channel === undefined) return null
            normalList.push({
                avatar: item.channel.avatar,
                fullName: null,
                userName: item.channel.title,
                followers: item.channel.subscriber_count,
                er: item.channel.er_views ? `${(item.channel.er_views * 100).toFixed(2)}` : '',
                aq: '-1',
                id: item.id,
                channelId: item.channel_id ? item.channel_id : '',
                external_id: item.channel_id ? item.channel_id : '',
                type: Platform.YouTube,
                has_email: item.channel.has_email,
                topics:
                    item.channel.topics && item.channel.topics.length !== 0
                        ? item.channel.topics[0].name.length !== 0
                            ? item.channel.topics[0].name[0].name
                            : undefined
                        : undefined,
                unlocked: item.channel_id ? ifUnlockedChannel(item.channel_id) : false,
                isLike:
                    likeBloggers.filter((e) => e.channel_id === item.channel_id).length > 0
                        ? likeBloggers.filter((e) => e.channel_id === item.channel_id)[0].id
                        : undefined,
                likedID:
                    likeBloggers.filter((e) => e.channel_id === item.channel_id).length > 0
                        ? likeBloggers.filter((e) => e.channel_id === item.channel_id)[0].id
                        : undefined,
                comment:
                    item.comments && item.comments.length > 0
                        ? { comment: item.comments[0].text, comment_id: item.comments[0].id }
                        : undefined,
            })
        }
    })
    return normalList
}
